.promo__content {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	max-width: 1280px;
	margin: 0 auto 0;
	padding: 75px 70px 80px;
	box-sizing: border-box;
}

.promo__title {
	max-width: 640px;
	margin: 0;
	color: white;
	font-size: 50px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 400;
	line-height: 58px;
	word-wrap: break-word;
  opacity: 0.8;
  margin-bottom: 155px;
}

.promo__link {
	box-sizing: border-box;
	margin: 0;
	padding: 10px 20px;
	border: 1px solid rgba(255, 255, 255, 0.6);
	border-radius: 3px;
	color: white;
	font-size: 12px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 500;
	line-height: 16px;
  opacity: 0.8;
}

.promo__link:hover {
  opacity: 0.5;
}

.promo__logo {
	max-width: 310px;
	height: 304px;
  background: linear-gradient(to top, black -15%, transparent 90%);
  opacity: 0.5;
}

@media screen and (max-width: 1100px) {
	.promo__content {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		margin: 0 auto 0;	
		box-sizing: border-box;
    padding: 30px 0;
	}
  .promo__link {
    display: none;
  }
  .promo__title {
		max-width: 680px;
		margin-top: 48px;
		font-size: 40px;
		line-height: 50px;
		text-align: center;
		margin-bottom: 22px;
	}
  .promo__info {
		display: flex;
		flex-direction: column;
	}
  .promo__logo {
		max-width: 310px;
		height: 304px;
	}
}

@media screen and (max-width: 900px) {
	.promo__title {
    font-size: 34px;
  }
}

@media screen and (max-width: 660px) {
	.promo__title {
    font-size: 30px;
    letter-spacing: 0;
  }
  .promo__logo {
		height: 270px;
	}
}

@media screen and (max-width: 590px) {
	.promo__title {
    font-size: 24px;
    line-height: 40px;
  }
  .promo__logo {
		height: 270px;
	}
}

@media screen and (max-width: 500px) {
	.promo__title {
		margin-top: 44px;
		font-size: 24px;
		line-height: 36px;
		margin-bottom: 24px;
		letter-spacing: -1.2px;
	}
  .promo__logo {
		max-width: 210px;
		height: 206px;
	}
}

@media screen and (max-width: 450px) {
	.promo__title {
		font-size: 20px;
		line-height: 30px;
    letter-spacing: 0;
	}
  .promo__logo {
		max-width: 210px;
		height: 170px;
	}
}

@media screen and (max-width: 390px) {
	.promo__title {
		font-size: 16px;
		line-height: 24px;
	}
  .promo__logo {
		height: 150px;
	}
}