.place {
	display: flex;
	flex-direction: column;
	border-radius: 10px 10px 10px 10px;
	/*background-color: #ffffff;*/
	color: #000000;
	margin: 0;
	padding: 0;
	position: relative;
}

.card__image {
	border-radius: 10px 10px 0 0;
	width: 282px;
	aspect-ratio: 1 / 1;
	display: block;
	background-position: center;
	background-size: cover;
  object-fit: contain;
}

.card__works {
	object-fit: cover;
}

.place__image:before {
	content: " ";
	position: absolute;
	left: 0;
	height: 282px;
	width: 100%;
	background-color: black;
	border: 1px dotted rgb(200, 200, 200);
	border-radius: 10px 10px 0 0;
}

.place__image:after {
	content: " Незагруженная картинка, на которой " attr(alt);
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 1.21;
	color: rgb(100, 100, 100);
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	left: 0;
	text-align: center;
	padding: 10px;
}

.card__name {
  display: none;
}

.card__name_type_monum {
  display: block;
  font-size: 20px;
margin: 5px 20px 0;
color: aliceblue;
}

.popup__save_condition_hover:hover {
	opacity: 0.8;
	cursor: pointer;
}

.place__image-button {
  background-color: black;
}