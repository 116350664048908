.footer {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	justify-content: space-between;
	border-top: 1px solid rgb(82, 80, 80);
	padding: 20px;
	padding-bottom: 0;
}

.footer__info {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.footer__logo {
	margin: 0;
	width: 100px;
	height: 100px;
	border-radius: 50%;
}

.footer__contact {
	display: flex;
	flex-direction: column;
	gap: 10px;
	color: white;
	font-size: 18px;
}

.footer__contact_mail {
	color: rgb(255, 255, 255, 0.8);
}

.footer__contact_tel {
	color: rgb(255, 255, 255, 0.8);
	text-decoration: none;
}

.footer__navigation {
	display: flex;
}

.footer__link {
	color: white;
	text-decoration: none;
}

.link__list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.link__list:first-child {
	margin-right: 40px;
}

.link__item {
	margin-bottom: 10px;
	opacity: 0.8;
}

.link__item:last-child {
	margin-bottom: 0;
}

.footer__admin {
	display: flex;
	justify-content: space-between;
}

.footer__link-admin {
	margin: 0;
	padding: 0;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 1.22;
	color: #545454;
	margin-top: 20px;
	text-decoration: none;
}

.footer__link-admin_disable {
	cursor: not-allowed;
}

@media screen and (max-width: 1100px) {
	.footer__contact {
		gap: 8px;
		font-size: 16px;
	}
}

@media screen and (max-width: 950px) {
	.footer__contact {
		gap: 6px;
		font-size: 12px;
	}
	.footer__logo {
		width: 80px;
		height: 80px;
	}
	.footer__link-admin {
		margin-left: 20px;
		margin-right: 20px;
	}
	.link__list {
		font-size: 14px;
	}
}

@media screen and (max-width: 800px) {
	.footer__link-admin {
		font-size: 14px;
		line-height: 1.22;
		color: #545454;
		margin-top: 20px;
	}
	.link__list:first-child {
		margin-right: 20px;
	}
}

@media screen and (max-width: 700px) {
	.footer__navigation {
		display: none;
	}
	.footer__info {
		justify-content: space-around;
	}
	.footer__link-admin {
		margin-left: 0px;
		margin-right: 0px;
	}
}

@media screen and (max-width: 600px) {
	.footer__contact {
		gap: 6px;
		font-size: 12px;
		grid-column: span 2;
		margin-top: 20px;
		align-items: center;
	}
	.footer__logo {
		width: 80px;
		height: 80px;
	}
}

@media screen and (max-width: 450px) {
	.footer {
		padding: 5px;
	}
	.footer__logo {
		width: 60px;
		height: 60px;
	}
	.footer__link-admin {
		margin: 10px 0 36px 0;
		font-size: 12px;
		line-height: 1.21;
	}
}
