.cards__title {
  text-align: center;
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 20px 80px;
  font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
  font-weight: 500;
  opacity: 0.8;
}

.grid-cards {
	max-width: 900px;
	display: grid;
	grid-template-columns: repeat(auto-fit, 282px);
	gap: 25px;
	margin: 51px auto 67px;
	justify-content: center;
}

@media screen and (max-width: 950px) {
	.grid-cards {
		width: 88%;
	}
}

@media screen and (max-width: 450px) {
	.grid-cards {
		margin-top: 0;
		margin-bottom: 50px;
	}
  .cards__title {
    font-size: 22px;
    padding: 20px 20px;
  }
}