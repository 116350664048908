.navigation__list {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  padding: 0;
  gap: 0;
}

.navigation__item {
	list-style-type: none;
	height: 30px;
	flex-grow: 1;
	background-color: rgb(14, 13, 13);
	margin-left: 10px;
}

.navigation__item:first-child {
margin: 0;
}

.navigation__link {
	display: flex;
	color: white;
	text-decoration: none;
	opacity: 0.8;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.navigation__link:hover {
	opacity: 0.5;
}

.disabled-link {
  pointer-events: none;
  opacity: 0.5;
  border-bottom: 1px solid white;
}

.button-popup {
	display: none;
}

.button-popup:hover {
	opacity: 0.6;
	cursor: pointer;
}


@media screen and (max-width: 900px) {
	.navigation__list {
    display: none;
  }
    .button-popup {
      display: block;
      border: none;
      background-image: url("../../images/nav_burger.svg");
      background-color: black;
      opacity: 0.8;
      width: 44px;
      height: 44px;
      margin-right: auto;
    }
}

@media screen and (max-width: 600px) {
    .button-popup {
      width: 35px;
      height: 35px;
    }
}