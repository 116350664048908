.popup_value_img {
	display: flex;
	padding: 0;
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.9);
	position: fixed;
	top: 0;
	left: 0;
	align-items: center;
	justify-content: center;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0.5s, opacity 0.5s linear;
  overflow-x:hidden;
  overflow-y:auto;
}

.popup__img {
  margin-top: 100px;
	position: relative;
  box-sizing: border-box;
}

.popup__close_value_img {
  width: 32px;
  height: 32px;
  position: absolute;
  top: -40px;
  right: -50px;
  background-image: url(../../images/CloseIcon.svg);
  background-color: transparent;
  background-size: cover;
}

.figure__caption {
	font-size: 20px;
	margin-top: 20px;
	line-height: 20px;
  height: 100%;
  margin-bottom: 10px;
  text-align: justify;
}

.figure {
	width: min-content;
	margin: 0;
}

.figure__img {
	display: block;
	background-size: cover;
	max-width: 75vw;
	max-height: 75vh;
	margin-left: auto;
	margin-right: auto;
}

@media screen and (max-width: 800px) {
  
  .popup__close_value_img {
      width: 26px;
      height: 26px;
    }
    .figure__caption {
      font-size: 12px;
      line-height: 18px;
    }
  }

  @media screen and (max-width: 550px) {
    
    .popup__close_value_img {
        width: 20px;
        height: 20px;
        right: -20px;
      }
  }  
