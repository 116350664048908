.feedback__content {
	color: white;
	opacity: 0.8;
	border: 1px rgb(95, 94, 94) solid;
	border-radius: 10px;
  width: 100%;
}

.feedback__title {
	margin: 0;
	padding: 20px 20px 0 20px;
	font-size: 14px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 500;
	text-align: start;
}

.feedback__date {
	margin: 0;
	padding: 4px 20px 0 20px;
	font-size: 14px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 400;
	text-align: start;
}

.feedback__text {
	box-sizing: border-box;
	margin: 0;
	padding: 10px 20px;
	font-size: 16px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	text-align: start;
	text-indent: 20px;
	max-height: 40px;
	max-width: 1200px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.feedback__button {
  width: 100%;
}

@media screen and (max-width: 900px) {
  
  .reviws__add-button {
    font-size: 14px;
    padding: 6px 16px;
  }
  
  .reviws__content {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    gap: 20px;
  }
  
  .feedback__title {
    padding: 10px 20px 0 20px;
    font-size: 12px;
  }
  
  .feedback__date {
    margin: 0;
    padding: 4px 20px 0 20px;
    font-size: 12px;
  }
  
  .feedback__text {
    margin: 0;
    padding: 10px 20px;
    font-size: 12px;
    text-indent: 20px;
    max-height: 40px;
    max-width: 1200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

}

@media screen and (max-width: 450px) {
  
  .reviws__add-button {
    font-size: 12px;
  }
  
  .feedback__title {
    padding: 5px 20px 0 20px;
    font-size: 12px;
  }
  
  .feedback__date {
    margin: 0;
    padding: 4px 20px 0 20px;
    font-size: 12px;
  }
  
  .feedback__text {
    margin: 0;
    padding: 10px 20px;
    font-size: 12px;
    text-indent: 20px;
    max-height: 40px;
    max-width: 1200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .feedback__button {
    max-width: 100%;
  }
}
