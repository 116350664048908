@font-face {
	font-family: "Inter";
	font-weight: 900;
	font-style: normal;
	font-display: swap;
	src: local("inter Web"), local("InterWeb"),
		url("../fonts/interWeb/Inter-Black.woff2") format("woff2"),
		url("../fonts/interWeb/Inter-Black.woff") format("woff");
}

@font-face {
	font-family: "Inter";
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	src: local("inter Web"), local("InterWeb"),
		url("../fonts/interWeb/Inter-Medium.woff2") format("woff2"),
		url("../fonts/interWeb/Inter-Medium.woff") format("woff");
}

@font-face {
	font-family: "Inter";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: local("inter Web"), local("InterWeb"),
		url("../fonts/interWeb/Inter-Regular.woff2") format("woff2"),
		url("../fonts/interWeb/Inter-Regular.woff") format("woff");
}
