.body {
	background-color: #000000;
	width: 100%;
	height: 100%;
	margin: 0;
}

.page {
	padding: 40px;
	max-width: 1280px;
	margin: 0 auto;
	color: #ffffff;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	box-sizing: border-box;
	text-rendering: optimizeLegibility;
}


@media screen and (max-width: 400px) {
	.page {
		padding: 28px 10px;
	}
}

.button {
	border: none;
	margin: 0;
	padding: 0;
	background: none;
}

.button_condition_hover:hover {
	opacity: 0.6;
	cursor: pointer;
}

@import url(./vendor/normalize.css);
@import url(./vendor/fonts/fonts.css);
