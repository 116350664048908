.tooltip {
  display: flex;
  padding: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
}

.tooltip_openend {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s, opacity 0.5s linear;
}

.tooltip__content {
	margin: 0;
	padding: 60px 36px;
	max-width: 430px;
	align-items: center;
	gap: 32px;
	color: #000000;
	background: #ffffff;
	box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	position: relative;
  background: #dddddd;
}

.tooltip__close {
  width: 29px;
  height: 29px;
  position: absolute;
  top: 23px;
  right: 24px;
  font-weight: 900;
  font-size: 24px;
  /*background-image: url(../../images/CloseIcon.svg);*/
  background-color: transparent;
  background-size: cover;
  border: none;
}

.tooltip__close:hover {
  opacity: 0.6;
  cursor: pointer;
}


.tooltip__img {
	width: 120px;
	height: 120px;
	object-fit: cover;
}

.tooltip__title {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	text-align: center;
	font-style: normal;
	font-weight: 900;
	font-size: 24px;
	line-height: 29px;
}

@media screen and (max-width: 800px) {
  .tooltip__content {
    max-width: 370px;
  }
	.tooltip__img {
    width: 90px;
    height: 90px;
  }
  
  .tooltip__title {
    font-weight: 900;
    font-size: 18px;
    line-height: 20px;
  }
}

@media screen and (max-width: 500px) {
  .tooltip__content {
    max-width: 270px;
  }

	.tooltip__img {
    width: 70px;
    height: 70px;
  }
  
  .tooltip__title {
    font-size: 16px;
    line-height: 14px;
  }
}
