.popup-with-form {
	display: flex;
	padding: 0;
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	align-items: center;
	justify-content: center;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0.5s, opacity 0.5s linear;
	overflow-y: scroll;
	height: 100%;
}

.popup__container-form {
	color: #000000;
	width: 700px;
	background: #c9c8c8;
	box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	padding: 0 36px 36px 36px;
	box-sizing: border-box;
	position: relative;
}

.button:disabled {
  opacity: 0.3;
}

.button:enabled {
  cursor: pointer;
}

.popup-with-form__close {
	width: 32px;
	height: 32px;
	position: absolute;
	top: -40px;
	right: -40px;
	background-image: url(../../images/CloseIcon.svg);
	background-color: transparent;
	background-size: cover;
}

.popup-with-form__title {
	font-style: normal;
	font-weight: 900;
	font-size: 24px;
	line-height: 1.21;
	margin: 35px 0 36px 0;
}

.popup-with-form__save {
	width: 358px;
	height: 50px;
	background-color: #000000;
	color: #ffffff;
	border-radius: 2px;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 1.22;
	text-align: center;
	margin-top: 20px;
	margin: 0 auto;
	display: block;
	margin-top: 20px;
	opacity: 0.8;
}

@media screen and (max-width: 900px) {
	.popup-with-form__title {
		font-size: 18px;
		line-height: 1.22;
		margin: 25px 0 62px 0;
	}
	.popup-with-form__close {
		width: 24px;
    height: 24px;
    top: -32px;
    right: -20px;
	}
	.popup__container-form {
		width: 550px;
		padding: 0 22px 27px 22px;
	}
}

@media screen and (max-width: 650px) {
	.popup-with-form__save {
		width: 238px;
		height: 46px;
		font-size: 14px;
		line-height: 1.21;
	}
	.popup-with-form__title {
		font-size: 18px;
		line-height: 1.22;
		margin: 25px 0 62px 0;
	}
	.popup-with-form__close {
		width: 20px;
		height: 20px;
		top: -36px;
		right: 0;
	}
	.popup__container-form {
		width: 282px;
		padding: 0 22px 27px 22px;
	}
}
