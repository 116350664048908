.reviws {
	margin: 40px 0;
}

.rewiews__title {
	text-align: center;
	font-size: 30px;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px 80px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 500;
	opacity: 0.8;
}

.rewiews__text {
	box-sizing: border-box;
	font-size: 16px;
	margin: 20px 0;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	opacity: 0.8;
	padding: 10px 20px;
	text-align: justify;
	text-indent: 20px;
	line-height: 24px;
}

.rewiews__mailto {
	margin: 0 10px;
	color: white;
	font-size: 16px;
}

.reviws__add-button {
	color: white;
	font-size: 16px;
	border: 1px rgb(95, 94, 94) solid;
	opacity: 0.8;
	padding: 10px 20px;
	border-radius: 7px;
	display: inline-block;
	background-color: rgb(14, 13, 13);
}

.reviws__content {
	display: flex;
	flex-direction: column;
	margin-top: 40px;
	gap: 20px;
}

@media screen and (max-width: 900px) {
	.reviws {
		margin: 0;
	}
	.rewiews__title {
		font-size: 24px;
		margin: 0;
	}

	.rewiews__text {
		font-size: 14px;
		line-height: 20px;
	}

	.rewiews__mailto {
		font-size: 14px;
	}
}

@media screen and (max-width: 450px) {

	.rewiews__title {
		font-size: 18px;
	padding: 5px;
	}

	.rewiews__text {
		font-size: 12px;
		line-height: 18px;
    margin-top: 10px;
    padding: 0;
	}

	.rewiews__mailto {
		font-size: 14px;
	}

  .reviws__add-button {
    font-size: 12px;
  }
}

