.contacts {
	margin: 100px 0;
	padding: 0 80px;
}

.contacts__title {
	text-align: center;
	font-size: 30px;
	margin-top: 20px;
	margin-bottom: 40px;
	padding: 20px 80px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 500;
	opacity: 0.8;
}

.contacts__subtitle {
	font-size: 30px;
	margin-top: 20px;
	margin-bottom: 40px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 500;
	opacity: 0.8;
}

.contacts__adress {
	font-size: 18px;
	margin: 20px 0;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 500;
	opacity: 0.8;
}

.contacts__iframe {
	opacity: 0.8;
	margin-bottom: 60px;
	width: 100%;
	aspect-ratio: 16 / 10;
}

.contacts__list {
	display: flex;
	justify-content: space-between;
	max-width: 746px;
	margin-bottom: 60px;
	opacity: 0.8;
}

.contacts__item {
	color: white;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-size: 22px;
	text-decoration: none;
	line-height: 30px;
}

.contacts__mailto {
	display: block;
	color: white;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-size: 22px;
	text-decoration: none;
	margin-bottom: 60px;
	opacity: 0.8;
}

.contacts__sosial {
	display: flex;
	justify-content: space-between;
	max-width: 746px;
	margin-bottom: 40px;
	opacity: 0.8;
	padding: 0 150px;
	box-sizing: border-box;
	align-items: end;
}

.contacts__tme {
	width: 120px;
	height: 138px;
}

.contacts__inst {
	width: 120px;
	height: 120px;
}

@media screen and (max-width: 1000px) {
	.contacts {
		padding: 0;
	}
}

@media screen and (max-width: 850px) {
	.contacts {
		margin: 40px 0;
	}
	.contacts__title {
		font-size: 24px;
	}

	.contacts__subtitle {
		font-size: 24px;
	}

	.contacts__adress {
		font-size: 15px;
	}

	.contacts__list {
		margin-bottom: 20px;
	}

	.contacts__item {
		font-size: 18px;
		line-height: 30px;
	}

	.contacts__mailto {
		font-size: 18px;
		margin-bottom: 40px;
	}

	.contacts__sosial {
		padding: 0 40px;
	}
}

@media screen and (max-width: 650px) {
	.contacts__list {
		flex-direction: column;
	}
	.contacts__item {
		margin-top: 20px;
		border-bottom: 1px solid rgb(78, 77, 77);
	}

  .contacts__tme {
    width: 90px;
    height: 106px;
  }
  
  .contacts__inst {
    width: 90px;
    height: 90px;
  }
}

@media screen and (max-width: 450px) {
	.contacts {
    margin: 20px 0;
  }

	.contacts__title {
    font-size: 20px;
    margin-bottom: 20px;
  }

	.contacts__subtitle {
    font-size: 18px;
    margin-bottom: 20px;
  }

	.contacts__adress {
    font-size: 12px;
    font-weight: normal;
  }

	.contacts__item {
    font-size: 14px;
    line-height: 22px;
    margin-top: 10px;
  }

	.contacts__mailto {
    font-size: 14px;
  }

	.contacts__tme {
    width: 60px;
    height: 74px;
  }
  
  .contacts__inst {
    width: 60px;
    height: 60px;
  }
}
