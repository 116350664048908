.info {
	background-image: url(../../images/info_fone.png);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
	height: 100%;
}

.info__title {
	text-align: center;
	font-size: 28px;
	margin-top: 20px;
	margin-bottom: 40px;
	padding: 20px 80px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 500;
	opacity: 0.8;
}

.info__main {
	margin-bottom: 30px;
	font-size: 18px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 500;
	opacity: 0.8;
	line-height: 24px;
}

.info__main_span {
	text-transform: uppercase;
	text-decoration: underline;
	position: relative;
	font-style: italic;
}

.info__main_span:hover {
	cursor: help;
	position: relative;
}

.info__main_span:hover:after {
	visibility: visible;
	transform: translate(-50%, -100%);
}

.info__main_span:after {
	width: 300px;
	visibility: hidden;
	position: absolute;
	transition: transform 0.5s ease;
	top: -3px;
	left: 50%;
	z-index: 2;
	transform: translate(-50%, 0%);
	background-color: black;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	text-transform: lowercase;
	font-style: normal;
	content: attr(data-hint);
	display: inline-block;
	padding: 10px;
	border-radius: 3px;
	font-size: 14px;
	line-height: 20px;
}

.servise {
	margin-top: 70px;
}

.servise__title {
	text-align: center;
	font-size: 28px;
	margin-top: 20px;
	margin-bottom: 40px;
	padding: 20px 80px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 500;
	opacity: 0.8;
	margin: 0;
	padding: 0;
	margin-bottom: 30px;
}

.servise__item {
	margin-bottom: 12px;
	font-size: 18px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 500;
	opacity: 0.8;
	line-height: 24px;
}

.servise__item:last-child {
	margin-bottom: 20px;
}

.servise__contact {
	margin: 0;
	margin-left: 40px;
	font-size: 18px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 400;
	opacity: 0.8;
	margin-bottom: 70px;
}

.servise__contact_tel {
	color: white;
	text-decoration: none;
	margin-left: 36px;
	font-style: oblique;
}

.servise__contact_tel {
	content: "\f095";
}

@media screen and (max-width: 1020px) {
	.info__main_span:after {
		width: 250px;
		font-size: 12px;
		line-height: 16px;
	}
}

@media screen and (max-width: 950px) {
	.servise__contact {
		margin-left: 20px;
		margin-bottom: 50px;
	}
	.servise__contact_tel {
		margin-left: 20px;
	}
}

@media screen and (max-width: 880px) {
	.servise__contact {
		display: flex;
		flex-direction: column;
		gap: 10px;
		font-size: 14px;
	}
	.info__title {
		font-size: 22px;
		margin-top: 10px;
		margin-bottom: 20px;
		padding: 10px 80px;
	}
	.info__main {
		font-size: 14px;
    margin-bottom: 20px;
	}
  .servise {
    margin-top: 20px;
}
	.servise__title {
		font-size: 22px;
    margin-bottom: 10px;
	}
	.servise__item {
		margin-bottom: 6px;
		font-size: 14px;
	}
}

@media screen and (max-width: 725px) {
	.info__title {
		padding: 10px 30px;
	}
}

@media screen and (max-width: 600px) {
  .info__main_span:after {
		width: 200px;
		font-size: 10px;
		line-height: 14px;
	}
	.servise__contact {
		gap: 8px;
		font-size: 12px;
    margin-bottom: 30px;
	}
	.info__title {
		font-size: 18px;
		margin-bottom: 10px;
	}
	.info__main {
		font-size: 12px;
	}
  .servise {
    margin-top: 20px;
}
	.servise__title {
		font-size: 18px;
    margin-bottom: 10px;
	}
	.servise__item {
		margin-bottom: 4px;
		font-size: 12px;
	}
}

@media screen and (max-width: 450px) {
  .info__main_span:after {
		width: 180px;
		font-size: 8px;
		line-height: 12px;
    padding: 6px;
	}
	.servise__contact {
		gap: 8px;
		font-size: 12px;
    margin-top: 0;
    margin-bottom: 14px;
	}
	.info__title {
		font-size: 14px;
		margin-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
	}
	.info__main {
		font-size: 10px;
    margin-bottom: 10px;
    line-height: 16px;
	}
  .servise {
    margin-top: 20px;
}
	.servise__title {
		font-size: 14px;
    margin-bottom: 0;
	}
  .servise__list {
    padding: 14px;
    margin: 0;
  }
	.servise__item {
		margin-bottom: 4px;
		font-size: 10px;
    line-height: 16px;
	}
}


