.nav-popup {
	display: none;
}

@media screen and (max-width: 900px) {
	.nav-popup {
		display: flex;
		padding: 0;
		width: 100%;
		max-height: 100vh;
		background: rgba(0, 0, 0, 0.3);
		position: fixed;
		top: 0;
		left: 0;
		align-items: start;
		justify-content: end;
		visibility: hidden;
		opacity: 0;
	}

	.nav-popup_openend {
		visibility: visible;
		opacity: 1;
		transition: visibility 0.5s, opacity 0.5s linear;
	}

	.nav-popup__close {
		width: 29px;
		height: 29px;
		position: absolute;
		top: 23px;
		right: 24px;
		background-image: url(../../images/CloseIcon.svg);
		background-color: transparent;
		background-size: cover;
		border: none;
	}

	.nav-popup__close:hover {
		opacity: 0.6;
		cursor: pointer;
	}

	.nav-popup__container {
		color: white;
		width: 520px;
		height: 100vh;
		background: #202020;
		box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding: 162px 0 0 0;
		box-sizing: border-box;
		position: relative;
	}

	.nav-popup__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 1;
		opacity: 0.8;
		gap: 10px;
	}
	.nav-popup__item {
		list-style-type: none;
		height: 30px;
		flex-grow: 1;
		margin-left: 10px;
		opacity: 0.8;
	}
}

@media screen and (max-width: 550px) {
	.nav-popup__close {
		width: 28px;
		height: 28px;
		top: 15px;
		right: 15px;
	}

	.nav-popup__container {
		padding: 147px 0 46px 0;
	}
}
