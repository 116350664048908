.add-popup_openend {
  visibility: visible;
	opacity: 1;
	transition: visibility 0.5s, opacity 0.5s linear;
}

.popup__fieldset {
	border: none;
	padding: 0;
	margin: 0;
}

.error1 {
  font-size: 12px;
  color: red;
  position: absolute;
  top: 86px;
  left: 50%;
  transform: translateX(-50%);
}

.error2 {
  font-size: 12px;
  color: red;
  line-height: 48px;
  position: absolute;
  top: 134px;
  left: 50%;
  transform: translateX(-50%);
}

.popup__input {
	border: none;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	width: 100%;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	border-bottom: 1px solid rgb(0, 0, 0, 0.2);
	padding: 5px 0 5px 0;
	margin: 0 0 0 0;
  background: #c9c8c8;
}

.popup__input:focus-visible {
	outline-color: rgb(55, 55, 236);
	outline-style: solid;
	outline-width: 1px;
}

.popup__input-first {
	margin: 0;
	padding-top: 12px;
}

.popup__input-error {
	display: block;
	min-height: 30px;
	visibility: hidden;
	padding: 0;
	margin: 0;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	color: #ff0000;
}

@media screen and (max-width: 650px) {
  .error1 {
    font-size: 9px;
    color: red;
    position: absolute;
    top: 94px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .error2 {
    font-size: 9px;
    color: red;
    line-height: 48px;
    position: absolute;
    top: 144px;
    left: 50%;
    transform: translateX(-50%);
  }
}
