.header {
	margin: 0 auto 0;
  padding: 0 20px;
	max-width: 1280px;
	padding: 35px 0 25px 0;
	border-bottom: 1px solid rgb(84, 84, 84, 0.7);
	display: flex;
	justify-content: space-between;
  background-image: url(../../images/grieving.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  align-items: center;
}

.header__contact_mail {
  color: rgb(255, 255, 255, 0.8);
}

.header__contact_tel {
  color: rgb(255, 255, 255, 0.8);
  text-decoration: none;
}

.header__contact {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  font-size: 18px;
}

.button__calback {
  color: white;
	font-size: 16px;
	opacity: 0.8;
	padding: 6px 4px;
	display:block;
	background-color: rgb(14, 13, 13);
}

.header__logo {
	margin: 0;
	width: 100px;
	height: 100px;
  border-radius: 50%;
}

.header__title {
  font-size: 40px;
  color: rgb(255, 255, 255, 0.8);
  text-align: center;
}

@media screen and (max-width: 1100px) {
	.header__title {
    font-size: 36px;
  }
  .header__contact {
    gap: 8px;
    font-size: 16px;
  }
}

@media screen and (max-width: 950px) {
	.header__title {
    font-size: 28px;
    margin: 0 20px;
  }
  .header__contact {
    gap: 6px;
    font-size: 12px;
  }
  .header__logo {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 600px) {
  .header {
    display: grid;
    grid-template-columns: auto 50%;
    justify-content: space-around;
  }
	.header__title {
    font-size: 28px;
    margin: 0;
    max-width: 250px;
    display: inline;
  }
  .header__contact {
    gap: 6px;
    font-size: 12px;
    grid-column: span 2;
    margin-top: 20px;
    align-items: center;
  }
  .header__logo {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 480px) {
  .header__logo {
    width: 60px;
    height: 60px;
	}
  .header__title {
    font-size: 20px;
  }
}
