.popup {
	display: flex;
	padding: 0;
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	align-items: center;
	justify-content: center;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0.5s, opacity 0.5s linear;
}

.popup_value_feedback {
	background: rgba(0, 0, 0, 0.9);
}

.popup_openend {
	visibility: visible;
	opacity: 1;
	transition: visibility 0.5s, opacity 0.5s linear;
}


.popup__feedback { 
	position: relative;
  margin: 0 80px;
  opacity: 0.8;
  border: 1px white solid;
  border-radius: 20px;
  padding: 40px;
}

.popup__close_value_feedback {
    width: 32px;
    height: 32px;
    position: absolute;
    top: -40px;
    right: -40px;
    background-image: url(../../images/CloseIcon.svg);
    background-color: transparent;
    background-size: cover;
  }
  

  .popup-feedback__title {
  margin: 0;
	padding: 20px 20px 0 20px;
	font-size: 20px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 500;
	text-align: start;
}

.popup-feedback__date {
  margin: 0;
	padding: 4px 20px 0 20px;
	font-size: 14px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 400;
	text-align: start;
}

.popup-feedback__text {
  box-sizing: border-box;
	margin: 0;
  margin-top: 20px;
	padding: 10px 20px;
	font-size: 20px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
  line-height: 36px;
	text-align: justify;
	text-indent: 40px;
}

@media screen and (max-width: 800px) {
  .popup__feedback { 
    margin: 0 40px;
    padding: 20px;
  }
  
  .popup__close_value_feedback {
      width: 26px;
      height: 26px;
      right: 0px;
    }
    
  
  .popup-feedback__text {
    font-size: 16px;
    line-height: 30px;
    text-indent: 20px;
  }
}

@media screen and (max-width: 550px) {
  .popup__feedback { 
    margin: 0 10px;
    padding: 10px;
  }
  
  .popup__close_value_feedback {
      width: 20px;
      height: 20px;
    }
    
  
    .popup-feedback__title {
    font-size: 14px;
  }
  
  .popup-feedback__date {
    font-size: 12px;
  }
  
  .popup-feedback__text {
    padding: 0 10px;
    font-size: 12px;
    line-height: 24px;
  }
  
}


