.button-history {
	color: white;
	background-color: rgb(36, 35, 35);
	padding: 10px 30px;
	margin: 0 auto 40px;
	display: block;
}

.button-history:hover {
  opacity: 0.8;
  cursor: pointer;
}
