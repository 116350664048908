.decoration__content {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	max-width: 1280px;
	margin: 0 auto 0;
	padding: 75px 70px 80px;
	box-sizing: border-box;
	height: max-content;
	align-items: center;
}

.decoration__title {
	max-width: 640px;
	margin: 0 40px;
	margin-bottom: 40px;
	color: white;
	font-size: 46px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 400;
	word-wrap: break-word;
	opacity: 0.8;
}

.decoration__text {
	max-width: 640px;
	margin: 0 40px;
	color: white;
	font-size: 17px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 400;
	line-height: 24px;
	word-wrap: break-word;
	opacity: 0.8;
	text-align: justify;
	text-indent: 30px;
  margin-bottom: 6px;
}

.decoration__link {
	box-sizing: border-box;
	margin: 0;
	padding: 10px 20px;
	border: 1px solid rgba(255, 255, 255, 0.6);
	border-radius: 3px;
	color: white;
	font-size: 12px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 500;
	line-height: 16px;
	opacity: 0.8;
}

.decoration__link:hover {
	opacity: 0.5;
}

.decoration__logo {
	max-width: 310px;
	background: linear-gradient(to top, black -15%, transparent 90%);
	opacity: 0.8;
}

.decoration-servise {
	margin: 0;
	padding: 0 70px;
}

.decoration-servise__title {
	text-align: start;
	font-size: 28px;
	margin-top: 20px;
	margin-bottom: 40px;
	padding: 20px 80px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 500;
	opacity: 0.8;
	margin: 0;
	padding: 0;
	margin-bottom: 30px;
}

.decoration-servise__item {
	margin-bottom: 12px;
	font-size: 18px;
	font-family: "Inter", "Source Sans Pro", "Arial", sans-serif;
	font-weight: 500;
	opacity: 0.8;
	line-height: 24px;
}

.decoration-servise__item:last-child {
	margin-bottom: 20px;
}

.decoration__grid {
  display:flex;
  flex-flow: row wrap;
  justify-content:center;
  align-content:center;
  align-items:center;
	max-width: 900px;
	gap: 25px;
	margin: 51px auto 67px;
}

.decoration__place {
	display: flex;
	flex-direction: column;
	border-radius: 10px 10px 10px 10px;
	background-color: #ffffff;
	color: #000000;
	margin: 0;
	padding: 0;
	position: relative;
}

.decoration__image {
	object-fit: cover;
	border-radius: 10px 10px 0 0;
	width: 282px;
	aspect-ratio: 1 / 1;
	display: block;
	background-position: center;
	background-size: cover;
	text-align: center;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
}

.crosses {
	background-image: url(../../images/decoration/crosses/cross1.jpg);
}

.candles {
	background-image: url(../../images/decoration/candles/candle3.png);
}

.flowers {
	background-image: url(../../images/decoration/flowers/flower16.jpg);
}

.icones {
	background-image: url(../../images/decoration/icons/icon1.jpg);
}

.decoration__image-text {
	font-size: 37px;
	font-weight: 900;
	color: rgb(151, 10, 10);
	letter-spacing: 0.2em;
	text-shadow: 2px 2px #ffffff;
}

.decoration__image-button {
	text-decoration: none;
}

@media screen and (max-width: 1100px) {
	.decoration__content {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		margin: 0 auto 0;
		padding: 40px 70px 76px;
		box-sizing: border-box;
		gap: 50px;
	}
  .decoration__title {
		max-width: 680px;
		margin-top: 48px;
		font-size: 40px;
		line-height: 50px;
		text-align: center;
		margin-bottom: 22px;
	}
  .decoration__info {
		display: flex;
		flex-direction: column;
	}

	.decoration__link {
		box-sizing: border-box;
		margin: 0 auto;
		padding: 10px 20px;
		border: 1px solid white;
		border-radius: 3px;
	}
  .decoration__logo {
		max-width: 310px;
		height: 304px;
	}
}

@media screen and (max-width: 950px) {
	.decoration__grid {
		width: 88%;
	}
}

@media screen and (max-width: 900px) {
	.decoration__title {
		font-size: 34px;
	}
	.decoration__text {
		font-size: 14px;
	}
  .decoration-servise__title {
    font-size: 24px;
  }
  .decoration-servise__item {
    font-size: 14px;
  }
  .decoration__content {
		padding: 40px 0 76px;
	}
}

@media screen and (max-width: 600px) {
	.decoration__title {
		font-size: 26px;
    margin-top: 10px;
	}
	.decoration__text {
		font-size: 14px;
	}
  .decoration-servise {
    padding: 0;
  }
  .decoration-servise__title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .decoration-servise__item {
    font-size: 14px;
  }
  .decoration__content {
		padding: 40px 8px 76px;
	}
}

@media screen and (max-width: 500px) {
	
  .decoration__title {
		max-width: 320px;
		margin-top: 0;
		font-size: 24px;
		line-height: 38px;
		margin-bottom: 24px;
		letter-spacing: -1.2px;
	}
  .decoration__text {
		font-size: 14px;
    margin: 0;
	}
  .decoration-servise__title {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .decoration-servise__item {
    font-size: 12px;
    margin-bottom: 6px;
  }
  .decoration__logo {
		max-width: 210px;
		height: 206px;
	}
}

@media screen and (max-width: 450px) {
	.decoration__grid {
		margin-top: 0;
		margin-bottom: 50px;
	}
}
